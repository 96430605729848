import { useEffect, useState } from "react";

import { AuthUser } from "@/types/auth_user";
import { auth } from "@/services/client/firebase";

const useFirebaseAuth = () => {
  const [authUser, setAuthUser] = useState<AuthUser | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (authState) => {
      if (!authState) {
        setAuthUser(null);
        setLoading(false);
        return;
      }
      await authState.reload();
      const idTokenResult = await auth.currentUser?.getIdTokenResult(true);
      const claims = idTokenResult?.claims;
      const token = idTokenResult?.token;
      const authUser = {
        uid: authState.uid,
        email: authState.email!,
        displayName: authState.displayName!,
        photoURL: authState.photoURL!,
        role: claims?.role ?? "normal",
        token,
      } as AuthUser;
      setAuthUser(authUser);
      setLoading(false);
    });
    return () => unsubscribe();
  }, []);

  return {
    authUser,
    loading,
  };
};

export default useFirebaseAuth;
