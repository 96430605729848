import { createContext, useContext } from "react";

import useFirebaseAuth from "@/lib/firebase/auth";
import { AuthUser } from "@/types/auth_user";

type Context = {
  authUser: AuthUser | null;
  loading: boolean;
};

const authUserContext = createContext<Context>({
  authUser: null,
  loading: true,
});

export function AuthUserProvider({ children }: any) {
  const auth = useFirebaseAuth();
  return (
    <authUserContext.Provider value={auth}>{children}</authUserContext.Provider>
  );
}

export const useAuth = () => {
  return useContext(authUserContext);
};
